import React from "react"

import Layout from "../components/layout"
import GridItem from "../components/gridItem"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div style={{ display: 'flex', padding: '1rem', paddingRight: '4rem'}}>
      <GridItem name={"Bingo!"} link="/bingo"/>
      <GridItem name={"Line-up!"} link="/timeline"/>
    </div>
  </Layout>
)

export default IndexPage
