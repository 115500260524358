import PropTypes from "prop-types"
import React from "react"
import * as GridItemStyles from "./gridItem.module.scss"
import { motion } from "framer-motion"

const GridItem = ({ name, link }) => (
  <motion.div
    className={GridItemStyles.container}
    whileHover={{scale: 1.1}}
    whileTap={{scale: 0.9}}
    onClick={() => { window.open(link, "_self") }}
  >
    <div className={GridItemStyles.text}>
      {name}
    </div>
  </motion.div>
)

GridItem.propTypes = {
  name: PropTypes.string,
  link: PropTypes.string
}

GridItem.defaultProps = {
  name: ``,
  link: "/"
}

export default GridItem
